<template>
    <b-modal
        :id="modalId"
        :ref="reference"
        :title="title"
        :ok-title="confirmButtonText"
        :cancel-title="closeButtonText"
        cancel-variant="outline-secondary"
        centered
        modal-class="modal-primary"
        @ok="okFunction"
        @cancel="cancelFunction"
        @hide="cancelFunction"
    >
        <b-card-text>
            {{ text }}
        </b-card-text>
    </b-modal>
</template>

<script>
import {
    BCardText, BModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
    components: {
        BCardText,
        BModal,
    },
    directives: {
        Ripple,
    },
    props: {
        reference: {
            type: String,
            default: 'confirmModal',
        },
        modalId: {
            type: String,
            default: 'confirmModal',
        },
        text: {
            type: String,
            default: '',
        },
        okFunction: {
            type: Function,
            default() { },
        },
        cancelFunction: {
            type: Function,
            default() { },
        },
        title: {
            type: String,
            default: 'Confirm',
        },
        confirmButtonText: {
            type: String,
            default: 'Yes',
        },
        closeButtonText: {
            type: String,
            default: 'Cancel',
        },
        disableEnterConfirm: {
            type: Boolean,
            default: false,
        },
    },
}
</script>
