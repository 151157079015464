<template>
    <div>
        <b-container
            class="mb-1"
            fluid
        >
            <b-row>
                <b-col
                    cols="6"
                >
                    <b-form-group
                        v-if="searchEnabled"
                    >
                        <div class="custom-search d-flex align-items-center">
                            <label class="mr-1">Search</label>
                            <b-form-input
                                v-model="searchTerm"
                                placeholder="Search"
                                type="text"
                                class="d-inline-block"
                            />
                        </div>
                    </b-form-group>
                </b-col>
                <b-col
                    cols="6"
                    class="text-right"
                >
                    <div v-for="(btn, index) in buttons" :key="index">
                        <b-button
                            variant="primary"
                            @click="btn.callback()"
                        >
                            {{ btn.label }}
                        </b-button>
                    </div>
                </b-col>
            </b-row>
        </b-container>

        <b-overlay
            :show="loading"
            spinner-type="border"
            spinner-variant="primary"
        >
            <vue-good-table
                v-if="!loading"
                :columns="columns"
                :rows="rows"
                :pagination-options="{
                    enabled: true,
                    perPage: recordsPerPage
                }"
                :theme="isDark ? 'nocturnal' : ''"
                @on-sort-change="onSortChange"
            >
                <template
                    slot="table-row"
                    slot-scope="props"
                >
                    <div v-if="props.column.type === XcTableHelper.TYPE_BADGE">
                        <b-badge class="text-center" :variant="'light-' + String(props.row[props.column.field].badge).slice(9, 16)">
                            {{ props.row[props.column.field].text }}
                        </b-badge>
                    </div>
                    <div v-else-if="props.column.type === XcTableHelper.TYPE_BUTTONS">
                        <span>
                            <b-dropdown
                                variant="link"
                                toggle-class="text-decoration-none"
                                no-caret
                            >
                                <template v-slot:button-content>
                                    <feather-icon
                                        icon="MoreVerticalIcon"
                                        size="16"
                                        class="text-body align-middle mr-25"
                                    />
                                </template>
                                <div v-for="(btn, index) in props.column.buttons"
                                     :key="index"
                                >
                                    <b-dropdown-item
                                        @click="btn.callback(props.row.record_id)"
                                    >
                                        <feather-icon
                                            :icon="btn.icon"
                                            :class="btn.class"
                                        />
                                        <span>
                                            {{ btn.label }}
                                        </span>
                                    </b-dropdown-item>
                                </div>
                            </b-dropdown>
                        </span>
                    </div>
                    <div v-else-if="props.column.type === XcTableHelper.TYPE_LINK">
                        <a href="javascript:" @click="props.column.callback(props.row.record_id)">
                            {{ props.row[props.column.field].text }}
                        </a>
                    </div>
                    <div v-else-if="props.column.type === XcTableHelper.TYPE_URL">
                        <router-link :to="{ name: props.column.routeName, params: { id: props.row.record_id }}">
                            {{ props.row[props.column.field].text }}
                        </router-link>
                    </div>
                    <div v-else>
                        {{ props.row[props.column.field].text }}
                    </div>
                </template>
                <template
                    slot="pagination-bottom"
                    slot-scope="props"
                >
                    <div class="d-flex justify-content-between flex-wrap">
                        <div class="d-flex align-items-center mb-0 mt-1">
                            <span class="text-nowrap">
                                Showing {{ props.total }} out of {{ totalRecords }} records
                            </span>
                        </div>
                        <div>
                            <b-pagination
                                :value="currentPage"
                                :total-rows="totalRecords"
                                :per-page="recordsPerPage"
                                first-number
                                last-number
                                align="right"
                                prev-class="prev-item"
                                next-class="next-item"
                                class="mt-1 mb-0"
                                @input="(value) => {
                                    props.pageChanged({ currentPage: value })
                                    moveToPage(value)
                                }"
                            >
                                <template #prev-text>
                                    <feather-icon
                                        icon="ChevronLeftIcon"
                                        size="18"
                                    />
                                </template>
                                <template #next-text>
                                    <feather-icon
                                        icon="ChevronRightIcon"
                                        size="18"
                                    />
                                </template>
                            </b-pagination>
                        </div>
                    </div>
                </template>
            </vue-good-table>
        </b-overlay>
    </div>
</template>

<script>
import Axios from 'axios'
import XcTableHelper from './XcTableHelper'
import { VueGoodTable } from 'vue-good-table'
import {
    BContainer,
    BCol,
    BRow,
    BFormInput,
    BButton,
    BBadge,
    BDropdown,
    BDropdownItem,
    BFormGroup,
    BOverlay,
    BPagination,
} from 'bootstrap-vue'
import 'vue-good-table/dist/vue-good-table.css'
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'

export default {
    components: {
        BContainer,
        BCol,
        BRow,
        BFormInput,
        BButton,
        BBadge,
        BDropdown,
        BDropdownItem,
        BFormGroup,
        BPagination,
        BOverlay,
        VueGoodTable,
    },
    props: {
        buttons: {
            type: Array,
            default: () => [],
        },
        searchEnabled: {
            type: Boolean,
            default: false,
        },
        endpoint: {
            type: String,
            required: true,
        },
        sortSingleColumns: {
            type: Boolean,
            default: true,
        },
        columns: {
            type: Array,
            default: () => [],
        },
        tableColumns: {
            type: Array,
            default: () => [],
        },
        recordsPerPage: {
            type: Number,
            default: XcTableHelper.DEFAULT_TAKE,
        },
    },
    data() {
        return {
            rows: [],
            totalRecords: 0,
            loading: true,
            currentPage: 1,
            search: '',
            loadDelay: null,
            XcTableHelper,
            orderBy: [],
            searchTerm: '',
        }
    },
    computed: {
        pagingPages() {
            let pageArray = []
            const totalPages = Math.ceil(this.totalRecords / this.recordsPerPage)
            if (totalPages > 7 && (this.currentPage <= 3 || this.currentPage >= (totalPages - 2))) {
                // for more than 7 pages and if user is on first 3 or last 3, shot the first 3 and last 3 pages
                pageArray = [
                    1,
                    2,
                    3,
                    '...',
                    totalPages - 2,
                    totalPages - 1,
                    totalPages,
                ]
            } else if (totalPages > 7) {
                // for more than 7 pages and if user is not on first or last 3 pages, show the current page and -3 and + 3 pages
                pageArray = [
                    this.currentPage - 3,
                    this.currentPage - 2,
                    this.currentPage - 1,
                    this.currentPage,
                    this.currentPage + 1,
                    this.currentPage + 2,
                    this.currentPage + 3,
                ]
            } else {
                // for 7 pages - show them all
                for (let i = 0; i < totalPages; i += 1) {
                    pageArray.push(i + 1)
                }
            }

            return pageArray
        },
        totalPages() {
            return Math.ceil(this.totalRecords / this.recordsPerPage)
        },

    },
    watch: {
        searchTerm() {
            this.searchAction()
        }
    },
    created() {
        for (const colIndex in this.columns) {
            const col = this.columns[colIndex]
            // set up sorts
            const sort = {
                sorting: false,
                sortDir: XcTableHelper.ORDER_BY_ASC,
            }

            if (col.sortByDefault) {
                sort.sorting = true
                sort.sortDir = col.sortByDefault === XcTableHelper.ORDER_BY_ASC ? XcTableHelper.ORDER_BY_ASC : XcTableHelper.ORDER_BY_DESC
            }

            if (!col.disableSort) {
                this.columns[colIndex].sort = sort
            }
        }
        this.loadData()
    },
    setup() {
        const { skin } = useAppConfig()
        const isDark = computed(() => skin.value === 'dark')

        return {
            isDark,
        }
    },
    methods: {
        loadData() {
            let delay = 200
            // initial load does not need any delay
            if (this.loadDelay === null) {
                delay = 1
            }
            clearTimeout(this.loadDelay)
            this.loadDelay = setTimeout(() => {
                const data = {}
                this.generateOrderBy()
                data[XcTableHelper.ORDER_BY_PARAM] = this.orderBy
                data[XcTableHelper.SEARCH_PARAM] = this.searchTerm
                data[XcTableHelper.PAGE_PARAM] = this.currentPage
                data[XcTableHelper.TAKE_PARAM] = this.recordsPerPage

                Axios({ url: process.env.VUE_APP_API_HTTP_ROOT + this.endpoint, data, method: 'POST' })
                    .then(resp => {
                        this.loading = false
                        this.rows = resp.data.data
                        this.totalRecords = resp.data.total
                    })
                    .catch(() => {
                        this.loading = false
                        this.rows = []
                    })
            }, delay)
            this.loading = true
        },
        searchAction() {
            this.moveToPage(1)
        },
        moveToPage(page) {
            this.currentPage = page
            this.loadData()
        },
        toggleSort(col) {
            this.clearOtherColumnSorts(col)
            if (col.sort.sorting && col.sort.sortDir === XcTableHelper.ORDER_BY_ASC) {
                col.sort.sorting = true
                col.sort.sortDir = XcTableHelper.ORDER_BY_DESC
            } else if (col.sort.sorting && col.sort.sortDir === XcTableHelper.ORDER_BY_DESC) {
                this.resetColumnSort(col)
            } else if (!col.sort.sorting) {
                col.sort.sorting = true
                col.sort.sortDir = XcTableHelper.ORDER_BY_ASC
            }
            this.loadData()
        },
        generateOrderBy() {
            let orderBy = []
            for (const colIndex in this.columns) {
                const col = this.columns[colIndex]
                if (typeof col.sort !== 'undefined' && col.sort.sorting === true) {
                    orderBy.push({ col: col.name, dir: col.sort.sortDir })
                }
            }

            if (orderBy.length === 0) {
                orderBy = [XcTableHelper.DEFAULT_ORDER_BY]
            }

            this.orderBy = orderBy
        },
        clearOtherColumnSorts(currentSortCol) {
            for (const column of this.columns) {
                if (column !== currentSortCol) {
                    this.resetColumnSort(column)
                }
            }
        },
        resetColumnSort(column) {
            if (!column.sort) {
                return
            }
            column.sort.sorting = false
            column.sort.sortDir = XcTableHelper.ORDER_BY_ASC
        },
        onSortChange(params) {
            this.toggleSort(this.columns.find(element => element.field === params[0].field))
        },
    },

}
</script>
