export const DynamicTableFieldTypes = Object.freeze({
    TEXT: 'text',
    CUSTOM: 'custom',
    CHECKBOX: 'checkbox',
    INPUT: 'input',
    IMAGE: 'image',
    IMAGE_URL: 'imageUrl',
    MAP: 'map',
    FLOAT: 'float',
    INT: 'int',
})

export class DynamicTableField {
    /**
     * @param type
     * @param name
     * @param data
     * @param key
     * @param enabledByDefault
     * @param required
     * @param valueKey
     * @param xcTableOptions
     * @param customFieldId
     * @param groupLabels
     */
    constructor({
        type, name, data, key, enabledByDefault, required, valueKey, xcTableOptions, customFieldId, groupLabels,
    }) {
        this.type = type
        this.name = name
        this.data = data
        this.key = key
        this.enabledByDefault = enabledByDefault
        this.required = !!required
        this.valueKey = valueKey
        this.xcTableOptions = xcTableOptions
        this.cusstomFieldId = customFieldId
        this.groupLabels = groupLabels
    }
}
