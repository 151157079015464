import { DynamicTableFieldTypes } from '@/structures/DynamicTableField'

export default class XcTableHelper {
    static DEFAULT_ORDER_BY = { col: 'id', dir: 'desc' };

    static DEFAULT_TAKE = 10;

    static ORDER_BY_PARAM = 'order_by';

    static FILTER_PARAM = 'filter';

    static ORDER_BY_ASC = 'asc';

    static ORDER_BY_DESC = 'desc';

    static SEARCH_PARAM = 'search';

    static TAKE_PARAM = 'take';

    static PAGE_PARAM = 'page';

    static REQUESTED_FIELDS_PARAM = 'requestedFields';

    static TYPE_TEXT = 'text';

    static TYPE_BADGE = 'badge';

    static TYPE_BUTTONS = 'buttons';

    static TYPE_LINK = 'link';

    static TYPE_URL = 'url';

    static TYPE_IMAGE = 'image';

    static TYPE_IMAGE_URL = 'imageUrl';

    static TYPE_MAP = 'map';

    static TYPE_CUSTOM = 'custom';
    static TYPE_NUMBER = 'number';

    static dynamicTableFieldTypeToXcTableType(dynamicTableFieldType) {
        switch (dynamicTableFieldType) {
            case DynamicTableFieldTypes.TEXT:
            case DynamicTableFieldTypes.CHECKBOX:
            case DynamicTableFieldTypes.INPUT:
                return XcTableHelper.TYPE_TEXT
            case DynamicTableFieldTypes.IMAGE:
                return XcTableHelper.TYPE_IMAGE
            case DynamicTableFieldTypes.IMAGE_URL:
                return XcTableHelper.TYPE_IMAGE_URL
            case DynamicTableFieldTypes.MAP:
                return XcTableHelper.TYPE_MAP
            case DynamicTableFieldTypes.CUSTOM:
                return XcTableHelper.TYPE_CUSTOM
            case DynamicTableFieldTypes.FLOAT:
            case DynamicTableFieldTypes.INT:
                return XcTableHelper.TYPE_NUMBER
            default:
                return XcTableHelper.TYPE_TEXT
        }
    }

    static getDynamicTableFieldFilterType(columnType) {
        switch (columnType) {
            case XcTableHelper.TYPE_TEXT:
                return 'agTextColumnFilter'
            case XcTableHelper.TYPE_NUMBER:
                return 'agNumberColumnFilter'
            default:
                return null
        }
    }
    static getDynamicTableFieldFilterOptions(columnType) {
        switch (columnType) {
            case XcTableHelper.TYPE_TEXT:
                return {
                    filterOptions: ['contains', 'equals', 'notEqual', 'startsWith', 'endsWith', 'blank', 'notBlank'],
                    defaultOption: 'contains',
                    suppressAndOrCondition: true,
                }
            case XcTableHelper.TYPE_NUMBER:
                return {
                    filterOptions: ['empty', 'equals', 'notEqual', 'lessThan', 'lessThanOrEqual', 'greaterThan', 'greaterThanOrEqual', 'blank', 'notBlank'],
                    defaultOption: 'equals',
                    suppressAndOrCondition: true,
                }
            default:
                return null
        }
    }
}
